import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { MDXLayout as PageLayout } from "../components/blocks/mdx-layout";
import { SEO } from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Uses" description="This is a living document of the software, tools, and hardware I use -- maybe you find it useful!" breadcrumbListItems={[{
      name: `Uses`,
      url: `/uses`
    }]} mdxType="SEO" />

    <h1 {...{
      "id": "uses"
    }}>{`Uses`}</h1>
    <p>{`This is a living document of the software, tools, and hardware I use — maybe you find it useful 😊`}</p>
    <h2 {...{
      "id": "editor--terminal"
    }}>{`Editor & Terminal`}</h2>
    <ul>
      <li parentName="ul">{`I primarily use my desktop PC with Windows 10 and occasionally my MacBook Pro with MacOS`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://code.visualstudio.com/"
        }}>{`Visual Studio Code`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.jetbrains.com/lp/mono/"
        }}>{`JetBrains Mono`}</a>{` as preferred font`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.zsh.org/"
        }}>{`zsh`}</a>{` + `}<a parentName="li" {...{
          "href": "https://ohmyz.sh/"
        }}>{`oh-my-zsh`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/denysdovhan/spaceship-prompt"
            }}>{`spaceship`}</a>{` as zsh prompt`}</li>
          <li parentName="ul">{`Plugins: git, zsh-autosuggestions, virtualenv, yarn, zsh-syntax-highlighting, history, gh`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/microsoft/terminal"
        }}>{`Windows Terminal`}</a>{` & `}<a parentName="li" {...{
          "href": "https://iterm2.com/"
        }}>{`iTerm2`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/nvm-sh/nvm"
        }}>{`nvm`}</a>{` as Node.js version manager`}</li>
      <li parentName="ul">{`On Windows 10 I use `}<a parentName="li" {...{
          "href": "https://ubuntu.com/wsl"
        }}>{`WSL 2 (Ubuntu)`}</a>{` as package manager for developing, on MacOS I use `}<a parentName="li" {...{
          "href": "https://www.macports.org/"
        }}>{`MacPorts`}</a></li>
    </ul>
    <h2 {...{
      "id": "software"
    }}>{`Software`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.adobe.com/products/photoshop.html"
        }}>{`Adobe Photoshop`}</a>{` & `}<a parentName="li" {...{
          "href": "https://www.captureone.com"
        }}>{`CaptureOne`}</a>{` for photo editing`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.maxon.net/en/cinema-4d/"
        }}>{`Cinema4D`}</a>{` for 3D art + `}<a parentName="li" {...{
          "href": "https://www.arnoldrenderer.com/"
        }}>{`Arnold`}</a>{` as renderer`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/"
        }}>{`Figma`}</a>{` for designs`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.duplicati.com/"
        }}>{`Duplicati`}</a>{` for offline + online backup strategy`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.enpass.io/"
        }}>{`Enpass`}</a>{` as password manager`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://justgetflux.com/"
        }}>{`f.lux`}</a>{` for proper coding night sessions`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.faststone.org/FSResizerDetail.htm"
        }}>{`FastStone Photo Resizer`}</a>{` as image batch processing tool`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://getsharex.com/"
        }}>{`ShareX`}</a>{` for taking screenshots, recording quick videos/gifs`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://streamlabs.com/"
        }}>{`Streamlabs OBS`}</a>{` for recording videos`}</li>
    </ul>
    <h2 {...{
      "id": "hardware"
    }}>{`Hardware`}</h2>
    <ul>
      <li parentName="ul">{`My main workstation has an `}<a parentName="li" {...{
          "href": "https://www.amd.com/en/products/cpu/amd-ryzen-9-3900x"
        }}>{`AMD Ryzen 9 3900X`}</a>{`, `}<a parentName="li" {...{
          "href": "https://www.gskill.com/product/165/170/1562828786/F4-3200C14D-32GFX-Overview"
        }}>{`32GB DDR4 G.Skill Flare X RAM`}</a>{`, `}<a parentName="li" {...{
          "href": "https://www.palit.com/palit/vgapro.php?id=3359&lang=en"
        }}>{`Palit GeForce RTX 2080 Super WGRP`}</a>{` and a `}<a parentName="li" {...{
          "href": "https://www.corsair.com/de/de/Kategorien/Produkte/Datenspeicher/M-2-SSDs/Force-Series%E2%84%A2-Gen-4-PCIe-NVMe-M-2-SSD/p/CSSD-F1000GBMP600"
        }}>{`1TB M.2 Corsair Force Series MP600`}</a>{` in a sleek `}<a parentName="li" {...{
          "href": "https://www.fractal-design.com/products/cases/define/define-r6/blackout/"
        }}>{`Fractal Design Define R6`}</a>{` case`}</li>
      <li parentName="ul">{`2x Dell Ultrasharp U2515H`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.corsair.com/de/de/Kategorien/Produkte/Gaming-Tastaturen/Mechanische-RGB-Gaming-Tastaturen/K70-LUX-RGB-Mechanical-Gaming-Keyboard-%E2%80%94-CHERRY%C2%AE-MX-SILENT-RGB-%28DE%29/p/CH-9101013-DE"
        }}>{`Corsair K70 LUX RGB`}</a>{` as keyboard`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.logitechg.com/de-de/products/gaming-mice/pro-wireless-mouse.html"
        }}>{`Logitech Pro Wireless`}</a>{` as mouse`}</li>
      <li parentName="ul">{`Video Setup: `}<a parentName="li" {...{
          "href": "https://www.logitech.com/de-de/products/webcams/c920-pro-hd-webcam.960-001055.html"
        }}>{`Logitech C920`}</a>{` + 2x Neewer 5600k USB-LED lights (powered by UGREEN 36W USB Charger) + 2x SMALLRIG Mini Magic Arm`}</li>
      <li parentName="ul">{`Audio Setup: `}<a parentName="li" {...{
          "href": "https://www.seelectronics.com/se-v7-dynamic-microphone"
        }}>{`SE Electronics V7`}</a>{` + `}<a parentName="li" {...{
          "href": "https://focusrite.com/de/usb-audio-interface/scarlett/scarlett-solo"
        }}>{`Focusrite Scarlett Solo 3rd Gen`}</a>{` + `}<a parentName="li" {...{
          "href": "https://www.tritonaudio.com/fethead"
        }}>{`TritonAudio FetHead`}</a>{` + `}<a parentName="li" {...{
          "href": "http://en.rode.com/accessories/Psa1"
        }}>{`Rode PSA1`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://fujifilm-x.com/en-us/products/cameras/x-t100/"
        }}>{`FujiFilm X-T100`}</a>{` with those Fujinon lenses:`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://fujifilm-x.com/en-us/products/lenses/xf16-80mmf4-r-ois-wr/"
            }}>{`XF16-80mm F4 R OIS WR`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://fujifilm-x.com/en-us/products/lenses/xf16mmf28-r-wr/"
            }}>{`XF16mm F2.8 R WR`}</a></li>
        </ul>
      </li>
    </ul>
    <hr></hr>
    <p>{`To learn what this page looked like in the past, checkout `}<a parentName="p" {...{
        "href": "https://github.com/LekoArts/portfolio-v2/commits/main/www/src/pages/uses.mdx"
      }}>{`the file history on GitHub`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      